/* eslint-disable @typescript-eslint/camelcase */
import { AddShipmentBookingRequestInterface } from "../contracts/ShipmentBookingRequest";

export class AddShipmentBooking implements AddShipmentBookingRequestInterface {
  sttManual: string;
  referenceExternal: string;
  productName: string;
  originCity: string;
  originDistrict: string;
  destinationCity: string;
  destinationDistrict: string;
  phoneSender: string;
  phoneReceiver: string;
  sender: string;
  receiver: string;
  addressSender: string;
  addressReceiver: string;
  commodityCode: string;
  insurance: string;
  statusGoods: string;
  estimationPrice: string;
  taxNumber: string;
  isWoodpack: boolean;
  isCOD: boolean;
  isDFOD: boolean;
  codHandling: string;
  isSaveSender: boolean;
  isSaveReceiver: boolean;
  sttPieces: Array<any>;
  isDO: boolean;
  isMixpack: boolean;
  codAmount: number;
  codFee: number;
  shipmentPackageId: number;
  shipmentId: string;
  sttPiecePerPack: any;
  sttNextCommodity: string;
  discountFavoritePercentage: number;
  elexysTariff?: any;
  postalCodeDestination?: string;
  sttWeightAttachFiles?: Array<string>;
  sttRecipientAddressType?: string;
  constructor(
    sttManual: string,
    referenceExternal: string,
    productName: string,
    originCity: string,
    originDistrict: string,
    destinationCity: string,
    destinationDistrict: string,
    phoneSender: string,
    phoneReceiver: string,
    sender: string,
    receiver: string,
    addressSender: string,
    addressReceiver: string,
    commodityCode: string,
    insurance: string,
    statusGoods: string,
    estimationPrice: string,
    taxNumber: string,
    isWoodpack: boolean,
    isCOD: boolean,
    isDFOD: boolean,
    codHandling: string,
    isSaveSender: boolean,
    isSaveReceiver: boolean,
    sttPieces: Array<any>,
    isDO: boolean,
    isMixpack: boolean,
    codAmount: number,
    codFee: number,
    shipmentPackageId: number,
    shipmentId: string,
    sttPiecePerPack: any,
    sttNextCommodity: string,
    discountFavoritePercentage: number,
    elexysTariff?: any,
    postalCodeDestination?: string,
    sttWeightAttachFiles?: Array<string>,
    sttRecipientAddressType?: string
  ) {
    this.sttManual = sttManual;
    this.referenceExternal = referenceExternal;
    this.productName = productName;
    this.originCity = originCity;
    this.originDistrict = originDistrict;
    this.destinationCity = destinationCity;
    this.destinationDistrict = destinationDistrict;
    this.phoneSender = phoneSender;
    this.phoneReceiver = phoneReceiver;
    this.receiver = receiver;
    this.sender = sender;
    this.addressReceiver = addressReceiver;
    this.addressSender = addressSender;
    this.commodityCode = commodityCode;
    this.insurance = insurance;
    this.statusGoods = statusGoods;
    this.estimationPrice = estimationPrice;
    this.taxNumber = taxNumber;
    this.isWoodpack = isWoodpack;
    this.isCOD = isCOD;
    this.isDFOD = isDFOD;
    this.codHandling = codHandling;
    this.isSaveSender = isSaveSender;
    this.isSaveReceiver = isSaveReceiver;
    this.sttPieces = sttPieces;
    this.isDO = isDO;
    this.isMixpack = isMixpack;
    this.codAmount = codAmount;
    this.codFee = codFee;
    this.shipmentPackageId = shipmentPackageId;
    this.shipmentId = shipmentId;
    this.sttPiecePerPack = sttPiecePerPack;
    this.sttNextCommodity = sttNextCommodity;
    this.discountFavoritePercentage = discountFavoritePercentage;
    this.sttWeightAttachFiles = sttWeightAttachFiles;
    this.elexysTariff = elexysTariff;
    this.postalCodeDestination = postalCodeDestination;
    this.sttRecipientAddressType = sttRecipientAddressType;
  }

  toJSON(): string {
    let objectPayload: any = {
      stt: {
        stt_no: this.sttManual,
        is_mixpack: this.isMixpack,
        stt_shipment_id: this.shipmentId,
        stt_no_ref_external: this.referenceExternal,
        stt_tax_number: this.taxNumber,
        stt_goods_estimate_price: this.estimationPrice,
        stt_goods_status: this.statusGoods,
        stt_origin_city_id: this.originCity,
        stt_origin_district_id: this.originDistrict,
        stt_destination_city_id: this.destinationCity,
        stt_destination_district_id: this.destinationDistrict,
        stt_sender_name: this.sender,
        stt_sender_phone: this.phoneSender,
        stt_sender_address: this.addressSender,
        stt_is_save_sender: this.isSaveSender,
        stt_recipient_name: this.receiver,
        stt_recipient_address: this.addressReceiver,
        stt_recipient_phone: this.phoneReceiver,
        stt_is_save_recipient: this.isSaveReceiver,
        stt_insurance_type: this.insurance,
        stt_product_type: this.productName,
        stt_commodity_code: this.commodityCode,
        stt_client_stt_id: 0,
        stt_vendor_stt_id: 0,
        stt_is_cod: this.isCOD,
        stt_is_dfod: this.isDFOD,
        cod_handling: this.codHandling,
        stt_is_do: this.isDO,
        stt_is_woodpacking: this.isWoodpack,
        stt_pieces: this.sttPieces,
        stt_cod_amount: this.codAmount,
        stt_cod_fee: this.codFee,
        postal_code_destination: this.postalCodeDestination,
        discount_favorite_percentage: this.discountFavoritePercentage
      },
      stt_weight_attach_files: this.sttWeightAttachFiles,
      shipment_package_id: this.shipmentPackageId,
      stt_piece_per_pack: this.sttPiecePerPack,
      stt_next_commodity: this.sttNextCommodity,
    };
    if (this.productName?.toLowerCase() === "sameday") {
      objectPayload = {...objectPayload, stt: {...objectPayload.stt, stt_recipient_address_type: this.sttRecipientAddressType} }
    }
    if (
      process.env.VUE_APP_MIGRATE_FROM_ELEXYS === "true" &&
      this.elexysTariff
    ) {
      const elexysTariffPayload = {
        elexys_tariff: {
          se_publish_rate: this.elexysTariff.se_elexys_publish_rate,
          se_forward_rate: this.elexysTariff.se_elexys_forward_rate,
          se_shipping_surcharge_rate: this.elexysTariff
            .se_elexys_shipping_surcharge_rate,
          se_commodity_surcharge_rate: this.elexysTariff
            .se_elexys_commodity_surcharge_rate,
          se_heavyweight_surcharge_rate: this.elexysTariff
            .se_elexys_heavyweight_surcharge_rate,
          se_document_surcharge_rate: this.elexysTariff
            .se_elexys_document_surcharge_rate,
          se_insurance_rate: this.elexysTariff.se_elexys_insurance_rate,
          se_wood_packing_rate: this.elexysTariff.se_elexys_woodpacking_rate,
          se_total_tariff: this.elexysTariff.se_elexys_total_tariff,
          se_etd: this.elexysTariff.se_elexys_etd,
          se_gross_weight: this.elexysTariff.se_elexys_gross_weight,
          se_weight: this.elexysTariff.se_elexys_weight,
          se_is_cod_area: this.elexysTariff.se_elexys_is_cod_area,
          se_volume_weight: this.elexysTariff.se_elexys_volume_weight,
          se_chargeable_weight: this.elexysTariff.se_elexys_chargeable_weight,
          se_elexys_total_normal_tariff: this.elexysTariff
            .se_elexys_total_normal_tariff,
          se_elexys_total_basic_tariff: this.elexysTariff
            .se_elexys_total_basic_tariff,
          stt_is_woodpacking: this.isWoodpack
      }
      };
      Object.assign(objectPayload, elexysTariffPayload);
    }
    return JSON.stringify(objectPayload);
  }
}
export class ShipmentInstantBooking
  implements AddShipmentBookingRequestInterface {
  shipmentPackageId = 0;
  stt = {
    sttNo: "",
    sttShipmentId: "",
    sttTaxNumber: "",
    sttGoodsEstimatePrice: 0,
    sttGoodsStatus: "",
    sttNoRefExternal: "",
    sttOriginCityId: "",
    sttDestinationCityId: "",
    sttOriginDistrictId: "",
    sttDestinationDistrictId: "",
    sttSenderName: "",
    sttSenderPhone: "",
    sttSenderAddress: "",
    sttRecipientName: "",
    sttRecipientPhone: "",
    sttRecipientAddress: "",
    sttProductType: "",
    sttCommodityCode: "" as object | string,
    sttInsuranceType: "",
    sttIsCod: false,
    sttIsDfod: false,
    sttCodAmount: 0,
    sttCodFee: 0,
    sttIsDo: false,
    sttPieces: [] as any,
    postalCodeDestination: "",
    addressReceiverType: "",
    sttWoodpacking: false
  };
  districtOrigin = {
    id: 0,
    code: "",
    name: "",
    ursaCode: "",
    city: {
      id: 0,
      code: "",
      name: ""
    }
  };
  districtDestination = {
    id: 0,
    code: "",
    name: "",
    ursaCode: "",
    city: {
      id: 0,
      code: "",
      name: ""
    }
  };
  commodity = {
    commodityId: 0,
    commodityCode: "" as object | string,
    commodityName: "" as object | string,
    commodityDocumentSurcharge: "",
    hsCode: ""
  };
  partner = {
    id: 0,
    name: "",
    code: "",
    partnerExternalCode: "",
    partnerLocation: {
      id: 0,
      districtCode: ""
    },
    partnerLocationList: [] as any
  };

  constructor(fields?: Partial<ShipmentInstantBooking>) {
    Object.assign(this, fields);
  }

  toJSON(): string {
    return JSON.stringify({
      shipment_package_id: this.shipmentPackageId,
      stt: {
        stt_no: this.stt.sttNo,
        stt_shipment_id: this.stt.sttShipmentId,
        stt_tax_number: this.stt.sttTaxNumber,
        stt_goods_estimate_price: this.stt.sttGoodsEstimatePrice,
        stt_goods_status: this.stt.sttGoodsStatus,
        stt_no_ref_external: this.stt.sttNoRefExternal,
        stt_origin_city_id: this.stt.sttOriginCityId,
        stt_destination_city_id: this.stt.sttDestinationCityId,
        stt_origin_district_id: this.stt.sttOriginDistrictId,
        stt_destination_district_id: this.stt.sttDestinationDistrictId,
        stt_sender_name: this.stt.sttSenderName,
        stt_sender_phone: this.stt.sttSenderPhone,
        stt_sender_address: this.stt.sttSenderAddress,
        stt_recipient_name: this.stt.sttRecipientName,
        stt_recipient_phone: this.stt.sttRecipientPhone,
        stt_recipient_address: this.stt.sttRecipientAddress,
        stt_recipient_address_type: this.stt.addressReceiverType,
        stt_product_type: this.stt.sttProductType,
        stt_commodity_code: this.stt.sttCommodityCode,
        stt_insurance_type: this.stt.sttInsuranceType,
        stt_is_cod: this.stt.sttIsCod,
        stt_is_dfod: this.stt.sttIsDfod,
        stt_cod_amount: this.stt.sttIsDfod ? 0 : this.stt.sttCodAmount,
        stt_cod_fee: this.stt.sttCodFee,
        stt_is_do: this.stt.sttIsDo,
        stt_pieces: this.stt.sttPieces,
        postal_code_destination: this.stt.postalCodeDestination,
        stt_is_woodpacking: this.stt.sttWoodpacking
      },
      district_origin: {
        id: this.districtOrigin.id,
        code: this.districtOrigin.code,
        name: this.districtOrigin.name,
        ursa_code: this.districtOrigin.ursaCode,
        city: {
          id: this.districtOrigin.city.id,
          code: this.districtOrigin.city.code,
          name: this.districtOrigin.city.name
        }
      },
      district_destination: {
        id: this.districtDestination.id,
        code: this.districtDestination.code,
        name: this.districtDestination.name,
        ursa_code: this.districtDestination.ursaCode,
        city: {
          id: this.districtDestination.city.id,
          code: this.districtDestination.city.code,
          name: this.districtDestination.city.name
        }
      },
      commodity: {
        commodity_id: this.commodity.commodityId,
        commodity_code: this.commodity.commodityCode,
        commodity_name: this.commodity.commodityName,
        commodity_document_surcharge: this.commodity.commodityDocumentSurcharge,
        hs_code: this.commodity.hsCode
      },
      partner: {
        id: this.partner.id,
        name: this.partner.name,
        code: this.partner.code,
        partner_external_code: this.partner.partnerExternalCode,
        partner_location: {
          id: this.partner.partnerLocation.id,
          district_code: this.partner.partnerLocation.districtCode
        },
        partner_location_list: this.partner.partnerLocationList
      }
    });
  }
}
