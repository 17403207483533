/* eslint-disable @typescript-eslint/camelcase */
import { getHubData } from "@/app/infrastructures/misc/Cookies";
import {
  CheckPaymentStatusRequestInterface,
  UpdatePodDexRequestInterface
} from "../contracts/PodDexRequest";
import { toFormDataUtils } from "@/app/infrastructures/misc/Utils";
import { QueryParamsEntities } from "@/domain/entities/MainApp";

export class UpdatePodDexApiRequest implements UpdatePodDexRequestInterface {
  attachment = "";
  sttNo = "";
  isDelivered = false;
  receiverName = "";
  notDeliveredReason = "";
  remarks = "";
  constructor(fields?: Partial<UpdatePodDexApiRequest>) {
    Object.assign(this, fields);
  }

  public toFormData(): FormData {
    const data = {
      attachment: this.attachment,
      stt_no: this.sttNo,
      is_delivered: this.isDelivered,
      reciever_name: this.receiverName,
      not_delivered_reason: this.notDeliveredReason,
      remarks: this.remarks,
      hub_id: getHubData()?.hubId,
      hub_name: getHubData()?.hubName,
      hub_district_code: getHubData()?.hubDistrictCode,
      hub_origin_city: getHubData()?.originCity
    };
    return toFormDataUtils(data);
  }
}

export class CheckPaymentStatusApiRequest
  implements CheckPaymentStatusRequestInterface {
  sttNo: string;
  constructor(sttNo: string) {
    this.sttNo = sttNo;
  }

  toJSON(): string {
    return JSON.stringify({
      stt_number: [this.sttNo]
    });
  }
}

export class RequestListPodDex {
  page = 1;
  limit = 10;
  search = "";
  startDate = "";
  endDate = "";
  type = "";
  status = "";
  version = "v1";
  typeCod = "";
  paymentMethod = "";
  constructor(fields?: Partial<RequestListPodDex>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}

export class ReasonListDex {
  statusCode = "";
  reasonStatus = "";
  isNotShownEnable = false;
  constructor(fields?: Partial<ReasonListDex>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}
